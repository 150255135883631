import React, { useState } from "react";
import style from "../assets/Scss/Pages/Department.module.scss";
import { services } from "../Confligration/Config";
import StepSection from "../component/StepSection";
import { Link } from "react-router-dom";

export default function Services() {
  // Initialize the first service as the default selected service
  const [selectedService, setSelectedService] = useState(services[0]);

  // Function to get services based on the selected service type
  const getSelectedService = () => {
    if (!selectedService) return [];
    return services.find((service) => service.id === selectedService.id) || services[0];
  };

  return (
    <div>
        <div className={style.banner}>
            <div className="container">
                <h1>Services</h1>
                <span><Link to="/">Home</Link> > Services</span>

            </div>
        </div>
      <div className="container">
        <div className={style.department_layout}>
          {/* Left side: Service List */}
          <div className={style.department_list}>
            <ul>
              {services.map((service, index) => (
                <li
                  key={index}
                  onClick={() => setSelectedService(service)}
                  className={
                    selectedService?.id === service.id ? style.active_department : ""
                  }
                >
                  {service.name}
                </li>
              ))}
            </ul>
          </div>

          <div className={style.department_dropdown}>
            <select
              onChange={(e) =>
                setSelectedService(
                  services.find((service) => service.id === e.target.value)
                )
              }
              value={selectedService?.id || ""}
            >
              {services.map((service) => (
                <option key={service.id} value={service.id}>
                  {service.name}
                </option>
              ))}
            </select>
          </div>

          {/* Right side: Service Details */}
          <div className={style.department_details}>
            {getSelectedService ? (
              <div>
                <div className={style.img}>
                  <img src={selectedService.img} alt={selectedService.name} />
                </div>
                <h2>{selectedService.name}</h2>
                <p>{selectedService.description}</p>
                <h6>{selectedService.description2}</h6>
                <ul>
                    {selectedService.why.map(list=> <li><p><span>{list.lable}</span> {list.pera}</p></li>)}
                    
                </ul>
              </div>
            ) : (
              <p>Please select a service from the left.</p>
            )}
          </div>
        </div>
      </div>
      <StepSection />
    </div>
  );
}
