import React from "react";
import styles from "../assets/Scss/componnets/Footer.module.scss"; // Import the CSS module
import { companyDetails, departMantSec, socilMediaIcon } from "../Confligration/Config";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo_dark.2.webp"
export default function Footer() {
  const navigate = useNavigate();

  const handleButtonClick = (id) => {
    // Navigate to the department page with the specified ID
    navigate(`/department/${id}`);
  };
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.footerContainer}>
          <div className={styles.footerLeft}>
            <img
              src={logo}
              alt="Vinayaka Hospital Logo"
              className={styles.footerLogo}
            />
            <p>
              Nurturing Health, One Patient at a Time.
              Your Journey to Wellness Starts Here
            </p>

            <h3>Our Location</h3>
            <a href="https://www.google.com/maps/place/Vinayaka+Multispeciality+Hospital+(Karanth+Hospital)/@12.9160033,77.6193334,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae1516d068a9b7:0xceafb7a54e3a9aec!8m2!3d12.9160033!4d77.6193334!16s%2Fg%2F11y7q94v9d?entry=ttu&g_ep=EgoyMDI0MTAyNy4wIKXMDSoASAFQAw%3D%3D" target="blank">
              <p>{companyDetails.address1}<br />{companyDetails.address2}<br />{companyDetails.address3}<br />{companyDetails.address4}</p>
              {/* <p>{companyDetails.address1}</p> */}
            </a>
            {/* <ul className={styles.sosialmidia}>
              {socilMediaIcon.map((ele) => (
                <li>
                  <Link to={ele.url}>
                    <i className={ele.icon}></i>
                  </Link>
                </li>
              ))}
            </ul> */}
          </div>

          <div className={styles.footerMiddle}>
            <h3>Center of Excellence</h3>
            {departMantSec.map(ele => <p onClick={() => handleButtonClick(ele.id)}>{ele.name}</p>)}
          </div>
          <div className={styles.footerRight}>
            <h3>Emergency Cases</h3>
            <a href="tel:9845301571" className={styles.emergencyNumber}>
              {companyDetails.emargencyNo}
            </a>
          </div>
        </div>
      </div>

      <div className={styles.footerBottom}>
        <p>©{new Date().getFullYear()} All rights reserved</p>
        <p>
          <i className="fa fa-heart" style={{ color: "red", marginRight: "4px" }}></i>
          Designed by <a href="">one08.tech</a> 
        </p>
      </div>
    </footer>
  );
}
