import React from 'react';
import style from "../assets/Scss/componnets/SepCard.module.scss";

export default function StepCard({ id, title, description, image }) {
  return (
    <div className={style.step_card}>
      <div className={style.circle}>
        <img src={image} alt={title} className={style.step_image} />
        <div className={style.badge}>
          <span>{id < 10 ? `0${id}` : id}</span>
        </div>
      </div>
      <h3 className={style.step_title}>{title}</h3>
      <p className={style.step_description}>{description}</p>
    </div>
  );
}
