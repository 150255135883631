import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Contact from "../Pages/Contact";
import Department from "../Pages/Department";
import Nav from "../component/Nav";
import Footer from "../component/Footer";
import DoctorDetails from "../Pages/DoctorDetails";
import Services from "../Pages/Services";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  return null;
}

export default function Navigation() {
  const [isScrollTopVisible, setScrollTopVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTopVisible(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Nav />
        <Routes>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="/department" element={<Department />} />
          <Route path="/department/:id" element={<Department />} />
          <Route path="doctor-details/:id" element={<DoctorDetails />} />
          <Route path="contact" element={<Contact />} />
          <Route path="services" element={<Services />} />

          {/* <Route path="*" element={<NoPage />} /> */}
        </Routes>
        <Footer />
        {isScrollTopVisible && (
          <button
            onClick={scrollToTop}
            className="scroll-top-button"
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: 1000,
              background: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              cursor: "pointer",
            }}
          >
            ↑
          </button>
        )}
      </BrowserRouter>
    </div>
  );
}
