import React from 'react';
import StepCard from './SepCards';
import style from '../assets/Scss/componnets/StepSection.module.scss';
import Heading from './Heading';

export default function StepSection() {
  const steps = [
    {
      id: 1,
      title: 'Make Appointment',
      description: 'Easily book your appointment at Vinayaka Hospital today online',
      image: '/images/img_1.webp', // Replace with your image link
    },
    {
      id: 2,
      title: 'Select Expert Doctor',
      description: 'Choose from expert doctors at Vinayaka Hospital for quality care.',
      image: '/images/img_2.webp', // Replace with your image link
    },
    {
      id: 3,
      title: 'Get Consultation',
      description: 'Get expert consultation at Vinayaka Hospital for personalized care',
      image: '/images/img_3.webp', // Replace with your image link
    },
    {
      id: 4,
      title: 'Get Cure & Relief',
      description: 'Find cure and relief with expert care at Vinayaka Hospital.',
      image: '/images/img_4.webp', // Replace with your image link
    },
  ];

  return (
    <div className={style.steps_section}>
    <div className="container">
     <Heading titel="How It Helps You Stay Strong"  subTitel="choose Doctor"/>
     
      <div className={style.steps_container}>
        {steps.map(step => (
          <StepCard
            key={step.id}
            id={step.id}
            title={step.title}
            description={step.description}
            image={step.image}
          />
        ))}
      </div>
        </div>
    </div>
  );
}
