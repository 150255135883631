import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "../assets/Scss/Pages/Department.module.scss";
import { departMantSec, doctorsByDepartment } from "../Confligration/Config";
import StepSection from "../component/StepSection";
import { Link } from "react-router-dom";

export default function Department() {
  const { id } = useParams(); // Get department ID from URL
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const navigate = useNavigate();
  const handelBooking = (id) => {
    navigate(`/doctor-details/${id}`); // Pass the doctor id in the URL
  };

  useEffect(() => {
    if (id) {
      const department = departMantSec.find((dep) => dep.id === id);
      setSelectedDepartment(department || departMantSec[0]); // If no matching department, default to first
    } else {
      setSelectedDepartment(departMantSec[0]);
    }
  }, [id]);

  // Function to get doctors by department
  const getDoctorsForSelectedDepartment = () => {
    if (!selectedDepartment) return [];
    return doctorsByDepartment[selectedDepartment.id] || [];
  };



  return (
    <div>
      <div className={style.banner}>
            <div className="container">
                <h1>Department</h1>
                <span><Link to="/">Home</Link> > Department</span>
            </div>
        </div>
      {/* Department Page Layout */}
      <div className="container">
        <div className={style.department_layout}>
          {/* Left side: Department List */}
          <div className={style.department_list}>
            <ul>
              {departMantSec.map((department, index) => (
                <li
                  key={index}
                  onClick={() => setSelectedDepartment(department)}
                  className={
                    selectedDepartment?.id === department.id
                      ? style.active_department
                      : ""
                  }
                >
                  {department.name}
                </li>
              ))}
            </ul>
          </div>

          <div className={style.department_dropdown}>
            <select
              onChange={(e) =>
                setSelectedDepartment(
                  departMantSec.find((dep) => dep.id === e.target.value)
                )
              }
              value={selectedDepartment?.id || ""}
            >
              {departMantSec.map((department) => (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
              ))}
            </select>
          </div>

          {/* Right side: Department Details */}
          <div className={style.department_details}>
            {selectedDepartment ? (
              <div>
                <div className={style.img}>
                  <img
                    src={selectedDepartment.img}
                    alt={selectedDepartment.name}
                  />
                </div>
                <h2>{selectedDepartment.name}</h2>
                <p>{selectedDepartment.description}</p>
                <p>{selectedDepartment.description2}</p>

                {/* Doctor List for the Selected Department */}
                <div className={style.doctor_list}>
                  {getDoctorsForSelectedDepartment().map((doctor, index) => (
                    <div className={style.card} key={index}>
                      <div className={style.image_container}>
                        <img
                          src={doctor.img}
                          alt={doctor.label}
                          className={style.card_image}
                        />
                        <button className={style.book_button} onClick={(e)=>handelBooking(doctor.id)}>
                          Book Appointment
                        </button>
                      </div>
                      <div className={style.card_body}>
                        <div className={style.social_icons}>
                          <h3 className={style.card_name}>{doctor.label}</h3>
                          {/* <div style={{display:"flex"}}>
                            <i className="fab fa-facebook"></i>
                            <i className="fab fa-linkedin"></i>
                          </div> */}
                        </div>
                        <p className={style.card_description}>
                          {doctor.department}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <p>Please select a department from the left.</p>
            )}
          </div>
        </div>
      </div>
      <StepSection />
    </div>
  );
}
