import React, { useState } from "react";
import style from "../assets/Scss/Pages/Home.module.scss";
import {
  aboutInfo,
  bannerDetails,
  companyDetails,
  departMantSec,
  departMentHeading,
  doctors,
  doctorsByDepartment,
} from "../Confligration/Config";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import {  FormControl } from "@mui/material";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../component/Heading";
import DoctorCard from "../component/DoctorCard";
import DoctorSnap from "../assets/images/dt_bg.webp";
import StepSection from "../component/StepSection";
import img from "../assets/images/scap_2.webp";
import Modal from "@mui/material/Modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { onClick } = props;
  return (
    <div>
      <div className={`${style.arrow} ${style.next}`} onClick={onClick}>
        <span>&#x2192;</span> {/* Unicode right arrow character */}
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div>
      <div onClick={onClick} className={`${style.arrow} ${style.prev}`}>
        <span>&#x2190;</span> {/* Unicode left arrow character */}
      </div>
    </div>
  );
}
export default function Home() {
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [name, setname] = useState("");
  const [age, setage] = useState();
  const [error, setError] = useState("");
  const [mobileNumber, setmobilenumber] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [sopen, ssetOpen] = React.useState(false);
  const shandleOpen = () => ssetOpen(true);
  const shandleClose = () => ssetOpen(false);
  const [baopen, basetOpen] = React.useState(false);
  const bahandleOpen = () => basetOpen(true);
  const bahandleClose = () => setOpen(false);
  const [verifyopen, setverifyopen] = React.useState(false);
  const verifyhandleOpen = () => setverifyopen(true);
  const verifyhandleClose = () => setverifyopen(false);
  const [selectedSlots, setSelectedSlots] = useState([null, null]); // for days
  const [showTimeSlots, setShowTimeSlots] = useState(null); // to track which day’s time slots to show

  const days = [
    {
      name: "Monday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
    {
      name: "Tuesday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
    {
      name: "Wednesday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
    {
      name: "Thursday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
    {
      name: "Friday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
    {
      name: "Saturday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
    {
      name: "Sunday",
      timeSlots: [
        "10:00AM - 12:10PM",
        "12:20PM - 2:30PM",
        "2:40PM - 4:50PM",
        "5:00PM - 7:00PM",
      ],
    },
  ];
  // const handleDepartmentChange = (event) => {
  //   const department = departMantSec.find(
  //     (dep) => dep.name === event.target.value
  //   );
  //   setSelectedDepartment(department);
  //   setSelectedDoctor(null); // Reset doctor selection when department changes
  //   setError(""); // Clear error
  // };

  const doctorOptions = selectedDepartment
    ? doctorsByDepartment[selectedDepartment.id] || []
    : [];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767, // Ensure this breakpoint applies correctly
        settings: {
          slidesToShow: 1, // Show 1 slide at a time below 500px
          slidesToScroll: 1,
        },
      },
    ],
  };

  const aboutImg = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767, // Ensure this breakpoint applies correctly
        settings: {
          slidesToShow: 2, // Show 1 slide at a time below 500px
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500, // Ensure this breakpoint applies correctly
        settings: {
          slidesToShow: 1, // Show 1 slide at a time below 500px
          slidesToScroll: 1,
          className: "center",
          centerMode: true,
          centerPadding: "60px",
      
        },
      },
    ],
  };

  // Slider Settings for the second slider
  const dctor = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // autoplay: true,
    // autoplaySpeed:2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();

  const handelBooking = (id) => {
    navigate(`/doctor-details/${id}`); // Pass the doctor id in the URL
  };
  const handleButtonClick = (id) => {
    // Navigate to the department page with the specified ID
    navigate(`/department/${id}`);
  };
  const handleDepartmentChange = (event, newValue) => {
    const department = newValue;
    setSelectedDepartment(department);
    setSelectedDoctor(null); // Reset doctor selection when department changes
    setError(""); // Clear error
  };

  return (
    <div>
      <section className={style.banner}>
        <div className="container">
          <div className={style.info}>
            {bannerDetails.map((ele) => {
              return (
                <div>
                  <h1 
                    className="wow slideInLeft"
                   data-aos="fade-up"
                  >
                    {ele.titel}
                  </h1>
                  <p data-aos="fade-up" data-aos-duration="1500">{ele.info}</p>
                  {/* <Button name="More About Hospital" /> */}
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className={style.em_book}>
        <div className="container">
          <div className={style.em_wrp}>
            <div className={style.em_info}>
              <div className={style.em_wrp}>
                <i className="fa-solid fa-headset"></i>
                <div>
                  <p>Emergency Cases</p>
                  <h3>{companyDetails.emargencyNo}</h3>
                </div>
              </div>
              <p>
                Our emergency and trauma care unit is staffed 24/7 with highly trained
                professionals ready to handle any medical crisis with efficiency
                and care
              </p>
              {/* <Button name="Contact us" /> */}
            </div>
            <div className={style.booking_box}>
              <h1>Book Your Appointment Today !</h1>
              <form>
                <div className={style.form__contorl}>
                  <div className={style.box}>
                    <FormControl fullWidth margin="normal">
                      <Autocomplete
                        options={departMantSec}
                        getOptionLabel={(option) => option.name || ""}
                        value={selectedDepartment || null}
                        onChange={(event, newValue) => {
                          handleDepartmentChange(event, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Department"
                            variant="outlined"
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <div className={style.box}>
                    <FormControl fullWidth margin="normal">
                      <Autocomplete
                        options={doctorOptions}
                        getOptionLabel={(option) => option.label}
                        value={selectedDoctor || null} // Ensure value is null if reset
                        onChange={(event, value) => {
                          setSelectedDoctor(value);
                          setError(""); // Clear error when doctor is selected
                        }}
                        autoSelect
                        blurOnSelect
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Doctor"
                            variant="outlined"
                          />
                        )}
                        renderOption={(props, option) => (
                          <div
                            {...props}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "10px 5px",
                              justifyContent: "space-between",
                            }}
                          >
                            <img
                              src={option.img}
                              alt={option.label}
                              style={{
                                width: 50,
                                height: 50,
                                borderRadius: "50%",
                                marginRight: 10,
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                padding: "10px ",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <strong>{option.label}</strong>
                              <span>{option.qualifications}</span>
                            </div>
                            <div style={{ flex: 1 }}>
                              <span>{option.additionalInfo}</span>
                            </div>
                          </div>
                        )}
                      />
                    </FormControl>
                  </div>
                </div>
              </form>
              <button className={style.btn} onClick={handleOpen}>
                Book Appointment
              </button>

              <Modal
                open={open}
                onClose={handleClose} // Make sure to define this function
              >
                <div
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 300,
                    justifyContent: "center",

                    boxShadow: 24,
                    p: 4,
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    padding: 20,
                    borderRadius: 10,
                  }}
                >
                  <p
                    style={{
                      color: "#5B5B5B",
                      fontSize: 18,
                      fontWeight: "700",
                      padding: 10,
                    }}
                  >
                    Book Appointment
                  </p>
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: 10,
                      color: "#3A3A3A",
                      padding: 10,
                      textAlign: "center",
                    }}
                  >
                    Please call on below number to book your appointment
                  </p>
                  <p
                    style={{
                      color: "#5B5B5B",
                      fontSize: 18,
                      fontWeight: "700",
                      padding: 10,
                    }}
                  >
                    {companyDetails.phoneAppoint}
                  </p>

                  <Link
                    style={{
                      backgroundColor: "#0097EE",
                      paddingLeft: "70px",
                      paddingRight: 70,
                      color: "white",
                      paddingBottom: 5,
                      paddingTop: 5,
                      borderRadius: 30,
                    }}
                    onClick={() => {
                      bahandleClose();
                    }}
                  >
                    Ok
                  </Link>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>

      <section className={style.about}>
        <div className={style.spna}>
          <img  src={img} alt="" />
        </div>
        <div className="container">
          <div className={style.about_wrp}>
            <div className={style.about_info}>
              <h5 >{aboutInfo.titel}</h5>
              <h1 data-aos="fade-left">{aboutInfo.heading}</h1>
              <p  data-aos="fade-left">{aboutInfo.description}</p>
              <h4>{aboutInfo.subTitel}</h4>
              <div className={style.certy_wrp}>
                <Slider  {...aboutImg}>
                  {aboutInfo.certiFy.map((ele) => {
                    return <img 
                     src={ele.img} alt="" />;
                  })}

                </Slider>
              </div>
            </div>

            <div className={style.img}  data-aos="fade-up">
              <div className={style.big_img} >
                <img
               
                  src="/images/big.webp"
                  alt=""
                />
              </div>
              <div className={style.small_img}>
                <img
            
                
                  src="/images/small.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={style.deepartment}>
        <div className="container">
          {/* heading  */}
          <Heading
            titel={departMentHeading.hading}
            subTitel={departMentHeading.titel}
            description={departMentHeading.description}
          />

          {/* <div className="department_wrp"> */}
          <Slider {...settings} className={style.slider}>
            {departMantSec.map((depart) => {
              return (
                <div className={style.box} data-aos="flip-left">
                  <div className={style.itme}>
                    <img  src={depart.img} alt="" />
                    <h3>{depart.name}</h3>
                    <p>{depart.description}</p>
                    <button
                      onClick={() => handleButtonClick(depart.id)} // Handle button click
                      className={style.more_info_button} // Add a class for styling
                    >
                      More info <i className="fa fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              );
            })}
          </Slider>
          {/* </div> */}
        </div>
      </section>

      <section className={style.doctor} id="doctors" >
        <div className={style.snap}>
          <img src={img} alt="" />
        </div>

        <div className="container">
          <Heading
            titel="MEET OUR BEST DOCTORS"
            subTitel="Choose  Doctors"
            description={departMentHeading.description}
          />

          <div className={style.doctors_list} >
            <Slider {...dctor} className={style.slider}>
              {doctors.map((doctor) => {
                return (
                  <DoctorCard
                    key={doctor.id}
                    image={
                      doctor.img ||
                      "https://img.freepik.com/free-photo/doctor-with-his-arms-crossed-white-background_1368-5790.jpg?t=st=1727120219~exp=1727123819~hmac=2862f4afa797570412b00b29295957771e0e86d9546332b0efd0e02870309872&w=740"
                    }
                    name={doctor.label}
                    department={doctor.department}
                    onPrass={(e) => handelBooking(doctor.id)}
                  />
                )
              })}
            </Slider>

            <div className={style.span}>
              <img src={DoctorSnap} alt="" />
            </div>
          </div>
        </div>
      </section>

      <StepSection />
    </div>
  );
}
