import React from 'react';
import style from "../assets/Scss/componnets/DoctorCard.module.scss";

export default function DoctorCard({ image, name, department,onPrass }) {
  return (
    <div className={style.card} data-aos="fade-up">
      <div className={style.image_container}>
        <img src={image} alt={name} className={style.card_image} />
        <button className={style.book_button} onClick={onPrass}>Book Appointment</button>
      </div>
      <div className={style.card_body}>
        <div className={style.social_icons}>
          <h3 className={style.card_name}>{name}</h3>
          {/* <div style={{display:"flex"}}>
            <i className="fab fa-facebook"></i>
            <i className="fab fa-linkedin"></i>
          </div> */}
        </div>
        <p className={style.card_description}>{department}</p>
      </div>
    </div>
  );
}
